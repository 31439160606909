// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-en-js": () => import("../src/pages/about.en.js" /* webpackChunkName: "component---src-pages-about-en-js" */),
  "component---src-pages-about-es-js": () => import("../src/pages/about.es.js" /* webpackChunkName: "component---src-pages-about-es-js" */),
  "component---src-pages-contact-en-js": () => import("../src/pages/contact.en.js" /* webpackChunkName: "component---src-pages-contact-en-js" */),
  "component---src-pages-contact-es-js": () => import("../src/pages/contact.es.js" /* webpackChunkName: "component---src-pages-contact-es-js" */),
  "component---src-pages-index-en-js": () => import("../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-es-js": () => import("../src/pages/index.es.js" /* webpackChunkName: "component---src-pages-index-es-js" */),
  "component---src-pages-multimedia-en-js": () => import("../src/pages/multimedia.en.js" /* webpackChunkName: "component---src-pages-multimedia-en-js" */),
  "component---src-pages-multimedia-es-js": () => import("../src/pages/multimedia.es.js" /* webpackChunkName: "component---src-pages-multimedia-es-js" */),
  "component---src-pages-product-en-js": () => import("../src/pages/product.en.js" /* webpackChunkName: "component---src-pages-product-en-js" */),
  "component---src-pages-product-es-js": () => import("../src/pages/product.es.js" /* webpackChunkName: "component---src-pages-product-es-js" */),
  "component---src-pages-products-en-js": () => import("../src/pages/products.en.js" /* webpackChunkName: "component---src-pages-products-en-js" */),
  "component---src-pages-products-es-js": () => import("../src/pages/products.es.js" /* webpackChunkName: "component---src-pages-products-es-js" */),
  "component---src-pages-service-en-js": () => import("../src/pages/service.en.js" /* webpackChunkName: "component---src-pages-service-en-js" */),
  "component---src-pages-service-es-js": () => import("../src/pages/service.es.js" /* webpackChunkName: "component---src-pages-service-es-js" */),
  "component---src-pages-services-en-js": () => import("../src/pages/services.en.js" /* webpackChunkName: "component---src-pages-services-en-js" */),
  "component---src-pages-services-es-js": () => import("../src/pages/services.es.js" /* webpackChunkName: "component---src-pages-services-es-js" */),
  "component---src-pages-sub-category-en-js": () => import("../src/pages/subCategory.en.js" /* webpackChunkName: "component---src-pages-sub-category-en-js" */),
  "component---src-pages-sub-category-es-js": () => import("../src/pages/subCategory.es.js" /* webpackChunkName: "component---src-pages-sub-category-es-js" */)
}

